@use 'src/css/breakpoint.scss' as *;

.modalOpen {
  overflow-y: hidden;
}

.modalBackground {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.modalWindow {
  position: relative;
  width: 520px;
  z-index: 5;
  background-color: var(--modal-bg-dark-color);
  padding: 35px 20px;
  box-shadow: rgb(0 0 0 / 20%) 0 0 0.428571rem 0;
  border-radius: 8px;
  max-height: 80vh;
  overflow-y: auto;

  @include maxMediaWidth(xs) {
    width: 100%;
    max-height: 100%;
    padding: 30px 10px;
  }
}

.stepCounter {
  font: var(--font-h9);
  color: var(--secondary-text-color);
  text-align: center;
}

.stepHeader {
  font: var(--font-h7);
  text-align: center;
  color: var(--secondary-text-color);
  margin: 20px 0 30px;
}

.stepSubHeader {
  display: block;
  font: var(--font-h11);
  color: var(--secondary-text-color);
  text-align: center;
  margin-bottom: 20px;

  & a {
    color: var(--accented-text-dark-color);
    text-decoration-line: underline;

    &:hover {
      color: var(--link-dark-color);
    }
  }
}

.error {
  font: var(--font-h11);
  white-space: pre-wrap;
  color: var(--error-text-color);
  margin-bottom: 20px;
}

.close {
  all: unset;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  transition: opacity var(--transition);

  &:hover {
    opacity: 0.5;
  }
}

.nextButton {
  width: 100%;
}
