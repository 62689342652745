@use 'src/css/breakpoint.scss' as *;

.questionSection {
  margin-bottom: 10px;
}

.questionSectionHeading {
  display: flex;
  background: var(--color-primary-alpha-10);
  padding: 0 20px;
  border-radius: 8px;
  height: 49px;
  justify-content: space-between;
  align-items: center;
  font: var(--font-h9);
  color: var(--secondary-text-color);

  @include maxMediaWidth(sm) {
    padding: 0 15px;
    column-gap: 10px;
  }
}

.sectionItemsWrapper {
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.starsBlock {
  display: flex;
  width: 132px;
  justify-content: space-between;
}

.starButton {
  all: unset;
  cursor: pointer;
}

.star {
  font-size: 20px;
  color: var(--light-menu-bg-hover-dark-color);

  &.active {
    color: var(--feedback-star-color);
  }
}

.otherDescription {
  display: flex;
  font-size: 14px;
  column-gap: 10px;

  .otherInfoLabel {
    font: var(--font-h9);
    color: var(--secondary-text-color);
  }

  .otherInfoInput {
    flex-grow: 2;
    font: var(--font-h11);
    color: var(--secondary-text-color);
    border: none;
    border-bottom: 1px solid var(--color-primary-alpha-20);
    background-color: transparent;
    outline: none;

    &::placeholder {
      color: var(--feedback-input-placeholder-color);
    }
  }
}

.commentAndFiles {
  position: relative;
  margin-top: 30px;
  margin-bottom: 25px;
}

.comment {
  width: 100%;
  height: 80px;
  font: var(--font-h11);
  color: var(--secondary-text-color);
  padding: 15px 45px 15px 15px;
  border: unset;
  border-radius: 8px;
  background-color: var(--color-primary-alpha-10);
  outline: none;
  resize: none;

  &::placeholder {
    color: var(--secondary-text-color);
  }
}

.camera {
  color: var(--primary-button-dark-color);
  font-size: 12px;
  transition: color var(--transition);
}

.files {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: var(--primary-button-bg-dark-color);
  border-radius: 8px;
  color: var(--primary-button-dark-color);
  width: 33px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color var(--transition);

  input {
    display: none;
  }

  &:hover {
    background-color: var(--primary-button-bg-hover-dark-color);

    .camera {
      color: var(--primary-button-hover-dark-color);
    }
  }
}

.file {
  color: var(--accented-text-dark-color);
  font: var(--font-h11);
  margin-top: 5px;
}

.contactWithMe {
  display: flex;
  column-gap: 30px;
  margin-bottom: 30px;

  input[type='email'] {
    flex-grow: 2;
    height: 40px;
    padding: 10px 15px;
    font: var(--font-h11);
    color: var(--secondary-text-color);
    border: none;
    border-radius: 8px;
    background-color: var(--color-primary-alpha-10);
    outline: none;

    &::placeholder {
      color: var(--secondary-text-color);
    }
  }

  @include maxMediaWidth(sm) {
    flex-direction: column;
    row-gap: 10px;
  }
}
