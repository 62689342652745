@use 'src/css/breakpoint.scss' as *;

.pointsBlock {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;

  @include maxMediaWidth(xs) {
    gap: 5px;
  }
}

.pointItem {
  all: unset;
  width: 42px;
  height: 42px;
  aspect-ratio: 1 / 1;
  background-color: var(--feedback-pointItem-bg-color);
  border-radius: 8px;
  color: var(--light-neutral-text-color);
  font: var(--font-h7);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include maxMediaWidth(xs) {
    width: unset;
    height: unset;
    flex: 1 1 30px;
    max-width: 42px;
    font-size: 14px;
    line-height: 22px;
  }

  &.pointGreen {
    &.active,
    &:hover {
      background-color: var(--success-text-color);
      color: var(--secondary-text-color);
    }
  }

  &.pointYellow {
    &.active,
    &:hover {
      background-color: var(--feedback-pointItem-yellow-bg-color);
      color: var(--primary-button-hover-dark-color);
    }
  }

  &.pointRed {
    &.active,
    &:hover {
      background-color: var(--error-text-color);
      color: var(--secondary-text-color);
    }
  }
}

.pointsDescription {
  display: flex;
  justify-content: space-between;
  font: var(--font-h11);
  color: var(--special-dark-color);
  margin-bottom: 40px;
}
