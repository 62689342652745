@use 'src/css/breakpoint.scss' as *;

.checkboxWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  position: relative;
  cursor: pointer;

  .checkboxIcon.checked {
    filter: brightness(0) saturate(100%) invert(98%) sepia(75%) saturate(5323%) hue-rotate(317deg)
      brightness(97%) contrast(97%);
  }

  &:disabled text {
    filter: brightness(0) saturate(100%) invert(38%) sepia(12%) saturate(0%) hue-rotate(305deg)
      brightness(96%) contrast(84%);
  }
}

.checkboxInput {
  width: 0;
  height: 0;
  position: absolute;
  visibility: hidden;
}

.checkboxIcon {
  width: 25px;
  height: 25px;
  min-width: 25px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7495%) hue-rotate(296deg)
    brightness(103%) contrast(102%);

  @include maxMediaWidth(sm) {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }
}

.text {
  font: var(--font-h10);
  color: var(--secondary-text-color);
}

.checkboxWrapper.light {
  .checkboxIcon {
    filter: brightness(0) saturate(100%) invert(71%) sepia(0%) saturate(2122%) hue-rotate(150deg)
      brightness(106%) contrast(95%);

    &.checked {
      filter: brightness(0) saturate(100%) invert(60%) sepia(73%) saturate(5267%) hue-rotate(194deg)
        brightness(105%) contrast(93%);
    }
  }

  .text {
    color: var(--accented-dark-color);
  }
}
